import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IStatesState } from './state.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'stateInfo' })
export class StateStore extends Store<IStatesState> {
  public constructor() {
    super({});
  }
}
