import { Injectable } from '@angular/core';
import { MapSettingStore } from './map-setting.store';

@Injectable({
  providedIn: 'root',
})
export class MapSettingAction {
  public constructor(private store: MapSettingStore) {}

  public toggleViewType(): void {
    this.store.update((current) => ({
      viewType: current.viewType === 'map' ? 'list' : 'map',
    }));
  }
}
