import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseMediaAPI } from 'sustainment-component';
import {
  IMediaCreateRequest,
  IMediaCreateResponse,
  SizeCode,
} from 'sustainment-models';

@Injectable()
export class LogosAPI extends BaseMediaAPI {
  private _relativeUrl = 'media/logo/';

  public upload(
    media: IMediaCreateRequest
  ): Observable<HttpEvent<IMediaCreateResponse>> {
    const formData = this.createFormData(media);
    return this.postWithProgress<IMediaCreateResponse>(
      this._relativeUrl,
      formData
    );
  }

  public uploadWithoutProgress(
    media: IMediaCreateRequest
  ): Observable<IMediaCreateResponse> {
    const formData = this.createFormData(media);
    return this.post<IMediaCreateResponse>(this._relativeUrl, formData);
  }

  public downloadLogo(logoId: string | null, size: SizeCode): Observable<Blob> {
    const url = this._relativeUrl + logoId + `?size=${size}`;
    return this.getBlob(url);
  }

  public deleteLogo(logoId: string, softDelete = true): Observable<unknown> {
    return this.delete(
      this._relativeUrl + logoId + `?softDelete=${softDelete}`
    );
  }
}
