import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { GetOpensearchVendorRequest, IGeoquery } from 'sustainment-models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'queryRes', resettable: true })
export class VendorSearchStore extends Store<IGeoqueryCustom> {
  public constructor() {
    super({
      searching: false,
      results: [],
      resultsCount: 0,
      request: { customFilters: {} },
    });
  }

  public setSearching(value: boolean): void {
    this.update({ searching: value });
  }
}

export interface GetOpensearchVendorRequestCustom
  extends GetOpensearchVendorRequest {
  locationName?: string;
}

export interface IGeoqueryCustom extends IGeoquery {
  opensearchRequest?: GetOpensearchVendorRequestCustom;
  initialSearch?: boolean;
  initialSearchLocation?: boolean;
}
