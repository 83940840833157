import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IMapSettingState } from 'sustainment-models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'mapSetting' })
export class MapSettingStore extends Store<IMapSettingState> {
  private constructor() {
    super({ mapSetting: undefined, viewType: 'map' });
  }
}
